/* App.css */
.App {
  margin-top: 20%;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Base styles for the calling booth */
.calling-booth {
  border: 4px solid #000;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 20px;
  width: 90%; /* Adjusted for responsiveness */
  max-width: 400px; /* Maximum width to maintain aesthetics on larger screens */
  margin: 20px auto; /* Ensure margin on all sides for smaller screens */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  position: relative;
  display: flex; /* Using flex to center the content */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
}

/* Header styles inside the booth */
.booth-header {
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  width: 100%; /* Ensures the header takes full width of the parent */
}

/* Main content area styles inside the booth */
.booth-main {
  text-align: center;
  width: 100%; /* Ensures the main content takes full width of the parent */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {
  .calling-booth {
    width: 95%; /* Slightly more width for very small devices */
    padding: 15px; /* Adjust padding to save space */
  }

  .booth-header {
    font-size: 16px; /* Smaller text on small screens */
  }
}
